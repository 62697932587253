import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import { baseurl } from '../../constants';
import './BookingList.css';
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal/DeleteConfirmationModal';

const StatusBadge = ({ status, isPaid = false }) => {
    const getStatusInfo = () => {
        let color, title, icon;
        
        if (status === 0) {
            color = 'bg-warning'; // Suspended/Pending - Yellow
            title = 'في انتظار قبول المزود';
            icon = 'fa-clock';
        } else if (status === 1) {
            if (isPaid) {
                color = 'bg-success'; // Confirmed and paid - Green
                title = 'تم الدفع';
                icon = 'fa-check-circle';
            } else {
                color = 'bg-info'; // Confirmed but not paid - Blue
                title = 'بانتظار الدفع';
                icon = 'fa-money-bill-wave';
            }
        } else if (status === 2) {
            color = 'bg-danger'; // Canceled - Red
            title = 'ملغي';
            icon = 'fa-times-circle';
        } else if (status === 3) {
            color = 'bg-secondary'; // Completed - Grey
            title = 'مكتمل';
            icon = 'fa-check-double';
        } else {
            color = 'bg-primary';
            title = 'غير معروف';
            icon = 'fa-question-circle';
        }
        
        return { color, title, icon };
    };

    const { color, title, icon } = getStatusInfo();

    return (
        <span className={`badge ${color} text-white status-badge`}>
            <i className={`fas ${icon} me-1`}></i>
            {title}
        </span>
    );
};

const PaymentMethodBadge = ({ method }) => {
    const getMethodColor = () => {
        switch (method?.toLowerCase()) {
            case 'cash':
            case 'نقدي':
                return 'bg-success';
            case 'card':
            case 'بطاقة':
                return 'bg-primary';
            case 'bank transfer':
            case 'تحويل بنكي':
                return 'bg-info';
            default:
                return 'bg-secondary';
        }
    };

    return (
        <span className={`badge ${getMethodColor()} text-white`}>
            {method}
        </span>
    );
};

const BookingList = () => {
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') || '1'));
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [bookingToDelete, setBookingToDelete] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        const page = parseInt(searchParams.get('page') || '1');
        setCurrentPage(page);
    }, [searchParams]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(baseurl + `api/booking?page=${currentPage}`);
                setData(response.data.items);
                setTotalPages(Math.ceil(response.data.total / 10));
            } catch (error) {
                console.error('Error fetching bookings:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [currentPage]);

    const initiateDelete = (booking) => {
        setBookingToDelete(booking);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        if (!bookingToDelete) return;
        
        setDeleteLoading(true);
        try {
            await axios.delete(baseurl + `api/booking/${bookingToDelete.id}`);
            setData(data.filter(item => item.id !== bookingToDelete.id));
            setShowDeleteModal(false);
            setBookingToDelete(null);
        } catch (error) {
            console.error('Error deleting booking:', error);
        } finally {
            setDeleteLoading(false);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const LoadingSkeleton = () => (
        <div className="row g-4">
            {[1, 2, 3, 4, 5, 6].map(i => (
                <div key={i} className="col-12 col-md-6 col-lg-4">
                    <div className="booking-card skeleton">
                        <div className="skeleton-content">
                            <div className="skeleton-line" style={{ width: '60%' }}></div>
                            <div className="skeleton-line" style={{ width: '40%' }}></div>
                            <div className="skeleton-line" style={{ width: '80%' }}></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const handlePageChange = (newPage) => {
        setSearchParams({ page: newPage.toString() });
    };

    // Function to render pagination with limited visible pages
    const renderPagination = () => {
        if (totalPages <= 1) return null;

        const maxVisiblePages = 5; // Maximum number of page buttons to show
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust start page if we're near the end
        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        const pageButtons = [];

        // First page button
        if (startPage > 1) {
            pageButtons.push(
                <button
                    key="first"
                    className={`page-btn ${currentPage === 1 ? 'active' : ''}`}
                    onClick={() => handlePageChange(1)}
                >
                    1
                </button>
            );

            // Ellipsis if there's a gap
            if (startPage > 2) {
                pageButtons.push(
                    <span key="ellipsis-start" className="page-ellipsis">...</span>
                );
            }
        }

        // Visible page buttons
        for (let i = startPage; i <= endPage; i++) {
            if (i === 1 || i === totalPages) continue; // Skip first and last page as they're handled separately
            pageButtons.push(
                <button
                    key={i}
                    className={`page-btn ${currentPage === i ? 'active' : ''}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        // Last page button
        if (endPage < totalPages) {
            // Ellipsis if there's a gap
            if (endPage < totalPages - 1) {
                pageButtons.push(
                    <span key="ellipsis-end" className="page-ellipsis">...</span>
                );
            }

            pageButtons.push(
                <button
                    key="last"
                    className={`page-btn ${currentPage === totalPages ? 'active' : ''}`}
                    onClick={() => handlePageChange(totalPages)}
                >
                    {totalPages}
                </button>
            );
        }

        return (
            <div className="pagination-container mt-4">
                <div className="pagination">
                    <button
                        className="page-btn page-nav"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        title="الصفحة السابقة"
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                    
                    {pageButtons}
                    
                    <button
                        className="page-btn page-nav"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        title="الصفحة التالية"
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="booking-container">
            <div className="booking-header">
                <h1 className="header-title">قائمة الحجوزات</h1>
                <div className="header-breadcrumb">
                    <i className="bx bx-home-alt"></i>
                    <span>الرئيسية</span>
                    <span>/</span>
                    <span>الحجوزات</span>
                </div>
            </div>

            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                <>
                    <div className="row g-4">
                        {data.map((item) => {
                            // Check if booking is paid
                            const isPaid = item.isPaid === true || item.isPaid === 1;
                            
                            // Determine status class for card styling
                            const getStatusClass = () => {
                                if (item.status === 0) return 'pending';
                                if (item.status === 1) return isPaid ? 'confirmed' : 'pending-payment';
                                if (item.status === 2) return 'cancelled';
                                if (item.status === 3) return 'completed';
                                return '';
                            };
                            
                            return (
                                <div key={item.id} className="col-12 col-md-6 col-lg-4">
                                    <div className={`booking-card ${getStatusClass()}`}>
                                        <div className="booking-card-header">
                                            <div className="booking-header-content">
                                                <div className="booking-id-container">
                                                    <h5 className="booking-number">حجز #{item.id}</h5>
                                                    <StatusBadge status={item.status} isPaid={isPaid} />
                                                </div>
                                                <div className="booking-cost">
                                                    <span className="cost-amount">{Number(item.totalCost).toFixed(2)} ريال</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="booking-card-body">
                                            <div className="booking-info">
                                                <div className="info-item">
                                                    <i className="fas fa-users"></i>
                                                    <span>{item.sets} أشخاص</span>
                                                </div>
                                                <div className="info-item">
                                                    <i className="fas fa-calendar-alt"></i>
                                                    <div className="dates">
                                                        <div>
                                                            <small>من:</small> {formatDate(item.startDate)}
                                                        </div>
                                                        <div>
                                                            <small>إلى:</small> {formatDate(item.endDate)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="booking-card-footer">
                                            <div className="actions-group">
                                                <Link to={`/admin/booking/detail/${item.id}`}>
                                                    <button className="btn-action btn-view" title="عرض التفاصيل">
                                                        <i className="fas fa-eye"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/service/detail/${item.serviceId}`}>
                                                    <button className="btn-action btn-service" title="عرض الخدمة">
                                                        <i className="fas fa-box"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/user/detail/${item.userId}`}>
                                                    <button className="btn-action btn-user" title="عرض المستخدم">
                                                        <i className="fas fa-user"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/provider/detail/${item.providerId}`}>
                                                    <button className="btn-action btn-provider" title="عرض مزود الخدمة">
                                                        <i className="fas fa-user-tie"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/booking/edit/${item.id}`}>
                                                    <button className="btn-action btn-edit" title="تعديل">
                                                        <i className="fas fa-edit"></i>
                                                    </button>
                                                </Link>
                                                {/* <button 
                                                    className="btn-action btn-delete" 
                                                    onClick={() => initiateDelete(item)}
                                                    title="حذف"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {renderPagination()}
                </>
            )}
            <DeleteConfirmationModal 
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleDelete}
                itemName={`#${bookingToDelete?.id}`}
                itemType="الحجز"
                loading={deleteLoading}
            />
        </div>
    );
};

export default BookingList;