import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";

export default function Users() {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();

  const getData = async () => {
    showLoader();
    const formdata = new FormData();
    formdata.append("searchData", search);
    formdata.append("pageNo", 1);
    
    try {
      const response = await fetch(baseurl + "user/get-accounts", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata
      });
      const json = await response.json();
      setData(json);
      hideLoader();
    } catch (error) {
      console.error(error);
      hideLoader();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">المستخدمين</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">المستخدمين</li>
            </ol>
          </nav>
        </div>
      </div>
      <hr />

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <div className="row justify-content-start mb-4">
              <div className="col-12 col-md-4">
                <div className="card-body row no-gutters align-items-center">
                  <div className="col">
                    <input 
                      autoComplete="off" 
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      className="form-control" 
                      type="search" 
                      placeholder="بحث في القائمة" 
                    />
                  </div>
                  <div className="col-auto ms-2">
                    <button 
                      onClick={getData}
                      className="btn btn-primary"
                    >
                      بحث
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th><span className="mx-3">م</span></th>
                  <th>الإسم</th>
                  <th>الإيميل</th>
                  <th>رقم الهاتف</th>
                  <th>صورة</th>
                  <th>تعديل</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <span className="mx-3">{index + 1}</span>
                    </td>
                    <td>{item.fullName}</td>
                    <td>{item.email}</td>
                    <td>{item.userName}</td>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <img 
                          src={baseurl + "/uploads/" + item.profileImage} 
                          className="rounded-full w-[44px] h-[44px]" 
                          alt="" 
                        />
                      </div>
                    </td>
                    <td>
                      <Link 
                        state={{ row: item }}
                        to="/admin/Product/detail"
                      >
                        <button type="button" className="btn btn-primary m-1">
                          <i className="far fa-eye" />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}