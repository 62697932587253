import React from 'react';

export default function PayoutList() {
  return (
    <div className="flex items-center justify-center h-full">
      <h1 className="text-2xl font-semibold text-gray-600">
        Payout List - Coming Soon
      </h1>
    </div>
  );
}
