import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl } from '../../constants';
import { Link } from 'react-router-dom';
import './ServiceEdit.css';

const FullScreenSlider = ({ images, onClose, startIndex = 0 }) => {
    const [currentIndex, setCurrentIndex] = useState(startIndex);
    const allImages = images.map(img => typeof img === 'object' ? URL.createObjectURL(img) : baseurl + "uploads/" + img.url);

    const handlePrevious = () => {
        setCurrentIndex(prevIndex => 
            prevIndex === 0 ? allImages.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex(prevIndex => 
            prevIndex === allImages.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Escape') onClose();
            if (e.key === 'ArrowLeft') handlePrevious();
            if (e.key === 'ArrowRight') handleNext();
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [onClose]);

    return (
        <div 
            className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ 
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                zIndex: 1060
            }}
            onClick={onClose}
        >
            <div 
                className="position-relative"
                style={{ maxWidth: '90%', maxHeight: '90vh' }}
                onClick={e => e.stopPropagation()}
            >
                <button
                    className="btn-close btn-close-white position-absolute top-0 end-0 m-3"
                    style={{ zIndex: 1061 }}
                    onClick={onClose}
                    aria-label="Close"
                />
                
                <img
                    src={allImages[currentIndex]}
                    alt={`Image ${currentIndex + 1}`}
                    className="img-fluid"
                    style={{ 
                        maxHeight: '90vh',
                        objectFit: 'contain'
                    }}
                />

                {allImages.length > 1 && (
                    <>
                        <button
                            className="btn btn-dark position-absolute top-50 start-0 translate-middle-y ms-2"
                            onClick={handlePrevious}
                            style={{ opacity: 0.7 }}
                        >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <button
                            className="btn btn-dark position-absolute top-50 end-0 translate-middle-y me-2"
                            onClick={handleNext}
                            style={{ opacity: 0.7 }}
                        >
                            <i className="fas fa-chevron-right"></i>
                        </button>
                        <div className="position-absolute bottom-0 start-50 translate-middle-x mb-4">
                            <div className="d-flex gap-2">
                                {allImages.map((_, index) => (
                                    <button
                                        key={index}
                                        className="btn btn-sm px-2 py-1"
                                        style={{
                                            backgroundColor: index === currentIndex ? '#fff' : 'rgba(255, 255, 255, 0.5)',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setCurrentIndex(index)}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const ImageGallery = ({ images, newImages, onImageClick, onDelete, onDeleteNew }) => {
    return (
        <div className="row g-3">
            {images.map((image, index) => (
                <div key={image.id} className="col-6 col-md-4 col-lg-3">
                    <div className="position-relative" style={{ cursor: 'pointer' }}>
                        <img
                            src={baseurl + "uploads/" + image.url}
                            alt={`Image ${index + 1}`}
                            className="img-fluid rounded"
                            style={{ 
                                width: '100%',
                                height: '150px',
                                objectFit: 'cover'
                            }}
                            onClick={() => onImageClick(index, 'existing')}
                        />
                        <button
                            className="btn btn-danger btn-sm position-absolute top-0 end-0 m-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(image.id);
                            }}
                            style={{ zIndex: 1 }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            ))}
            {newImages.map((image, index) => (
                <div key={index} className="col-6 col-md-4 col-lg-3">
                    <div className="position-relative" style={{ cursor: 'pointer' }}>
                        <img
                            src={URL.createObjectURL(image)}
                            alt={`New Image ${index + 1}`}
                            className="img-fluid rounded"
                            style={{ 
                                width: '100%',
                                height: '150px',
                                objectFit: 'cover'
                            }}
                            onClick={() => onImageClick(index, 'new')}
                        />
                        <button
                            className="btn btn-danger btn-sm position-absolute top-0 end-0 m-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteNew(index);
                            }}
                            style={{ zIndex: 1 }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </button>
                        <div className="position-absolute top-0 start-0 m-1 badge bg-warning">
                            جديد
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

// Service Edit Component
const ServiceEdit = () => {
    const { id } = useParams();
    const [item, setItem] = useState({
        id: '', 
        name: '', 
        name_en: '', 
        image: '', 
        desc: '', 
        desc_en: '',
        price: '', 
        location: '', 
        city: '', 
        address: '', 
        descAddress: '', 
        startTime: '', 
        maxBookings: '', 
        minPersons: '',
        statuse: true,
        country: 'Saudi Arabia',
        duration: 0,
        eventDate: '',
        cost: 0,
        late: 0,
        lng: 0,
        categoryId: 1,
        userId: '',
        bundleId: 1,
        payments: 'cash,card',
        countBookings: 0,
        totalPersons: 0,
        discount: 0,
        featured: false,
        lnk: '',
        meta: '',
        isBooked: 0,
        arrange: 1,
        minBookingHoures: 24,
        availableFrom: '',
        availableTo: ''
    });
    const [images, setImages] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedImageType, setSelectedImageType] = useState(null);
    const [initialImages, setInitialImages] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const navigate = useNavigate();

    const updateServiceImages = async (serviceId, imageUrls) => {
  
            console.log('Updating service images with:', { serviceId, imageUrls });
            
            // Convert image URLs to Photo objects
            const photos = imageUrls.map(url => ({
                url: url,
                modle: "Service",
                modleId: serviceId.toString()
            }));

            console.log('Converted to photos:', photos);

            const response = await axios.post(
                `${baseurl}api/service/update-images`,
                {
                    serviceId: parseInt(serviceId),
                    images: photos
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('Update images response:', response.data);

            if (response.data.success) {
                return response.data;
            } else {
                throw new Error(response.data.message || 'Failed to update images');
            }
       
    };

    const uploadImages = async () => {
        try {
            console.log('Starting image upload for', newImages.length, 'images');
            
            // Upload new images
            const uploadPromises = newImages.map(async (file) => {
                const formData = new FormData();
                formData.append('image', file);
                console.log('Uploading file:', file.name);
                const response = await axios.post(baseurl + 'image/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                console.log('Upload response for', file.name, ':', response.data);
                return response.data.image;
            });

            const uploadedUrls = await Promise.all(uploadPromises);
            console.log('All images uploaded:', uploadedUrls);
            // Get current URLs from existing images
            const currentUrls = images.map(img => img.url);
            console.log('Current image URLs:', currentUrls);

            const allUrls = [...currentUrls, ...uploadedUrls];
            console.log('Combined URLs:', allUrls);

            // Update service with all images
            await updateServiceImages(id, allUrls);
            
            console.log('Service images updated successfully');
        } catch (error) {
            console.error('Error in uploadImages:', error.response || error);
            throw error;
        }
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        setNewImages(prev => [...prev, ...imageFiles]);
    };

    const handleDeleteNewImage = (index) => {
        setNewImages(prev => prev.filter((_, i) => i !== index));
    };

    const handleImageDelete = async (imageId) => {
        if (window.confirm('هل أنت متأكد من حذف هذه الصورة؟')) {
            setImages(prev => prev.filter(img => img.id !== imageId));
        }
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);
        
        setImageLoading(true);
        try {
            const response = await axios.post(baseurl + 'image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            setItem(prev => ({
                ...prev,
                image: response.data.image
            }));
            setUploadedImage(URL.createObjectURL(file));
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image. Please try again.');
        } finally {
            setImageLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        
        try {
            console.log('Starting service update...');
            
            // First update the service details
            const updateResponse = await axios.put(baseurl + `api/service/${id}`, item);
            console.log('Service update response:', updateResponse.data);
            
            // Then upload and update images if there are any new ones
            if (newImages.length > 0) {
                console.log('New images found, starting upload...');
                await uploadImages();
            }
            
            // If there were images deleted, update the service images
            const hasDeletedImages = images.length < initialImages.length;
            if (hasDeletedImages) {
                console.log('Updating service with deleted images...');
                const currentUrls = images.map(img => img.url);
                await updateServiceImages(id, currentUrls);
            }
            
            console.log('All updates completed successfully');
            navigate('/admin/service');
        } catch (error) {
            console.error('Error in handleSubmit:', error.response || error);
            let errorMessage = 'حدث خطأ أثناء حفظ التغييرات. يرجى المحاولة مرة أخرى.';
            
            if (error.response?.data?.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            
            alert(errorMessage);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        const fetchItem = async () => {
            setLoading(true);
            try {
                const response = await axios.get(baseurl + `api/service/` + id);
                setItem(response.data.item);
                setImages(response.data.images || []);
                setInitialImages(response.data.images || []);
            } catch (error) {
                console.error('Error fetching service:', error);
            }
            setLoading(false);
        };
        fetchItem();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({
            ...prev,
            [name]: name === 'statuse' ? value === 'true' : value
        }));
    };

    if (loading) {
        return (
            <div className="container-fluid p-4">
                <div className="text-center py-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">جاري التحميل...</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="m-0">تعديل الطلعة</h3>
                <Link to="/admin/service" className="btn btn-outline-secondary">
                    <i className="fas fa-arrow-right ms-1"></i>
                    العودة للقائمة
                </Link>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row g-4">
                    {/* Main Info Card */}
                    <div className="col-md-8">
                        <div className="card shadow-sm">
                            <div className="card-header bg-transparent">
                                <h5 className="card-title m-0">المعلومات الأساسية</h5>
                            </div>
                            <div className="card-body">
                                <div className="row g-3">
                                    {/* Image Upload Section */}
                                    <div className="col-12 mb-3">
                                        <label className="form-label">صورة الطلعة الرئيسية</label>
                                        <div className="d-flex gap-3 align-items-center">
                                            {(uploadedImage || item.image) && (
                                                <div className="position-relative" style={{ width: '150px', height: '150px' }}>
                                                    <img
                                                        src={uploadedImage || (item.image ? baseurl + 'uploads/' + item.image : '')}
                                                        alt="Service"
                                                        className="img-fluid rounded"
                                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                    />
                                                </div>
                                            )}
                                            <div className="d-flex flex-column gap-2">
                                                <label className="btn btn-outline-primary mb-0">
                                                    {imageLoading ? (
                                                        <span>
                                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                            جاري الرفع...
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <i className="fas fa-upload me-2"></i>
                                                            رفع صورة جديدة
                                                        </>
                                                    )}
                                                    <input
                                                        type="file"
                                                        className="d-none"
                                                        accept="image/*"
                                                        onChange={handleImageUpload}
                                                        disabled={imageLoading}
                                                    />
                                                </label>
                                                {(uploadedImage || item.image) && (
                                                    <small className="text-muted">
                                                        اسم الملف: {item.image || 'New image'}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <label className="form-label">الاسم</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={item.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">الاسم (إنجليزي)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name_en"
                                            value={item.name_en}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="price-label required">السعر / ساعة</label>
                                        <div className="price-input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="price"
                                                value={item.price}
                                                onChange={handleChange}
                                                placeholder="أدخل السعر"
                                                min="0"
                                                step="0.01"
                                                required
                                            />
                                            <span className="currency-suffix">ريال</span>
                                            <i className="fas fa-tag price-icon"></i>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">الوصف</label>
                                        <textarea
                                            className="form-control"
                                            name="desc"
                                            value={item.desc}
                                            onChange={handleChange}
                                            rows="3"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">الوصف (إنجليزي)</label>
                                        <textarea
                                            className="form-control"
                                            name="desc_en"
                                            value={item.desc_en}
                                            onChange={handleChange}
                                            rows="3"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Settings Card */}
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-header bg-transparent">
                                <h5 className="card-title m-0">الإعدادات</h5>
                            </div>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-12">
                                        <label className="form-label">الحالة</label>
                                        <select
                                            className="form-select"
                                            name="statuse"
                                            value={item.statuse.toString()}
                                            onChange={handleChange}
                                        >
                                            <option value="true">نشط</option>
                                            <option value="false">غير نشط</option>
                                        </select>
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">وقت البدء</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="startTime"
                                            value={item.startTime}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label className="form-label">الحد الأدنى للأشخاص</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="minPersons"
                                            value={item.minPersons}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label className="form-label">أقصى عدد للحجوزات</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="maxBookings"
                                            value={item.maxBookings}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Location Card */}
                    <div className="col-12">
                        <div className="card shadow-sm">
                            <div className="card-header bg-transparent">
                                <h5 className="card-title m-0">معلومات الموقع</h5>
                            </div>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label className="form-label">المدينة</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            value={item.city}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">العنوان</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="address"
                                            value={item.address}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">وصف العنوان</label>
                                        <textarea
                                            className="form-control"
                                            name="descAddress"
                                            value={item.descAddress}
                                            onChange={handleChange}
                                            rows="2"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Images Card */}
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-header bg-transparent">
                                <h5 className="card-title m-0">الصور</h5>
                            </div>
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label d-block">
                                        <div className="btn btn-outline-primary w-100">
                                            <i className="fas fa-upload me-2"></i>
                                            إضافة صور جديدة
                                        </div>
                                        <input
                                            type="file"
                                            className="d-none"
                                            accept="image/*"
                                            multiple
                                            onChange={handleImageChange}
                                        />
                                    </label>
                                </div>
                                
                                <ImageGallery 
                                    images={images}
                                    newImages={newImages}
                                    onImageClick={(index, type) => {
                                        setSelectedImageIndex(index);
                                        setSelectedImageType(type);
                                    }}
                                    onDelete={handleImageDelete}
                                    onDeleteNew={handleDeleteNewImage}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="col-12">
                        <div className="d-flex gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => navigate('/admin/service')}
                            >
                                إلغاء
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={saving}
                            >
                                {saving ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        جاري الحفظ...
                                    </>
                                ) : (
                                    <>
                                        <i className="fas fa-save ms-1"></i>
                                        حفظ التغييرات
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            {selectedImageIndex !== null && (
                <FullScreenSlider
                    images={selectedImageType === 'new' ? newImages : images}
                    startIndex={selectedImageIndex}
                    onClose={() => {
                        setSelectedImageIndex(null);
                        setSelectedImageType(null);
                    }}
                />
            )}
        </div>
    );
};

export default ServiceEdit;