import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { baseurl, formateDate } from '../constants';
import { useNavigate } from 'react-router-dom';
import './Chat.css';

// Icons
const SearchIcon = () => <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const AttachmentIcon = () => <svg width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24"><path d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const EmojiIcon = () => <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm4-9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-8 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm4 8a5 5 0 004.95-4.2A.5.5 0 0016.5 14h-9a.5.5 0 00-.45.8A5 5 0 0012 19z"/></svg>;
const SendIcon = () => <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/></svg>;
const MessageIcon = () => <svg width="48" height="48" fill="currentColor" viewBox="0 0 24 24"><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/></svg>;
const ImageIcon = () => <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0-2-.9-2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/></svg>;
const VoiceIcon = () => <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.91-3c-.49 0-.9.36-.98.85C16.52 14.2 14.47 16 12 16s-4.52-1.8-4.93-4.15c-.08-.49-.49-.85-.98-.85-.61 0-1.09.54-1 1.14.49 3 2.89 5.35 5.91 5.78V20c0 .55.45 1 1 1s1-.45 1-1v-2.08c3.02-.43 5.42-2.78 5.91-5.78.1-.6-.39-1.14-1-1.14z"/></svg>;
const LocationIcon = () => <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>;
const PlayIcon = () => <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"/></svg>;

// Add color generation function at the top
const getRandomColor = (name) => {
  const colors = [
    '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD',
    '#D4A5A5', '#9B59B6', '#3498DB', '#1ABC9C', '#F1C40F',
    '#E74C3C', '#2ECC71', '#E67E22', '#34495E', '#16A085'
  ];
  
  // Generate a consistent index based on the name
  const index = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colors[index % colors.length];
};

const getInitials = (name) => {
  if (!name) return '?';
  return name.split(' ')[0][0]?.toUpperCase();
};

const AvatarCircle = ({ name, profileImage, size = 48 }) => {
  const backgroundColor = getRandomColor(name);
  
  // If profile image is provided, render it
  if (profileImage) {
    const imageUrl = profileImage.startsWith('http') 
      ? profileImage 
      : `${baseurl}${profileImage.startsWith('/') ? profileImage.substring(1) : profileImage}`;
    
    return (
      <div
        className="avatar-circle"
        style={{
          width: size,
          height: size,
          borderRadius: '50%',
          overflow: 'hidden'
        }}
      >
        <img 
          src={imageUrl} 
          alt={name} 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          onError={(e) => {
            // Fallback to initials if image fails to load
            e.target.style.display = 'none';
            e.target.parentNode.style.backgroundColor = backgroundColor;
            e.target.parentNode.style.color = '#FFFFFF';
            e.target.parentNode.style.display = 'flex';
            e.target.parentNode.style.alignItems = 'center';
            e.target.parentNode.style.justifyContent = 'center';
            e.target.parentNode.style.fontSize = `${size * 0.4}px`;
            e.target.parentNode.style.fontWeight = '600';
            e.target.parentNode.innerHTML = getInitials(name);
          }}
        />
      </div>
    );
  }
  
  // Fallback to initials if no profile image
  return (
    <div
      className="avatar-circle"
      style={{
        width: size,
        height: size,
        backgroundColor,
        color: '#FFFFFF',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: size * 0.4,
        fontWeight: '600',
      }}
    >
      {getInitials(name)}
    </div>
  );
};

// Add a new component for rendering different message types
const MessageContent = ({ message }) => {
  // Construct the full media URL for images and voice messages
  const getMediaUrl = (mediaPath) => {
    if (!mediaPath) return null;
    // Remove leading slash if present to avoid double slashes
    const cleanPath = mediaPath.startsWith('/') ? mediaPath.substring(1) : mediaPath;
    return `${baseurl}${cleanPath}`;
  };

  switch (message.type) {
    case 'image':
      return (
        <div className="message-media">
          <img 
            src={getMediaUrl(message.mediaUrl)} 
            alt="Shared image" 
            className="message-image"
            onClick={() => window.open(getMediaUrl(message.mediaUrl), '_blank')}
          />
        </div>
      );
    
    case 'voice':
      return (
        <div className="message-voice">
          <div className="voice-player">
            <button 
              className="play-button"
              onClick={() => {
                const audio = new Audio(getMediaUrl(message.mediaUrl));
                audio.play();
              }}
            >
              <PlayIcon />
            </button>
            <div className="voice-waveform">
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
              <div className="waveform-bar"></div>
            </div>
            <span className="voice-duration">0:15</span>
          </div>
        </div>
      );
    
    case 'location':
      // Use Google Maps Static API with the provided API key
      const googleMapsUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${message.latitude},${message.longitude}&zoom=15&size=300x150&scale=2&maptype=roadmap&markers=color:red%7C${message.latitude},${message.longitude}&key=AIzaSyDFZhFfswZpcjeUDYm6C7H46JLdSonK0f4`;
      
      return (
        <div className="message-location">
          <div 
            className="location-preview"
            onClick={() => window.open(`https://maps.google.com/?q=${message.latitude},${message.longitude}`, '_blank')}
          >
            <div className="location-map">
              <img 
                src={googleMapsUrl} 
                alt="Location Map"
                className="static-map-image"
                onError={(e) => {
                  // Fallback if Google Maps API fails
                  e.target.style.display = 'none';
                  e.target.parentNode.classList.add('map-loading');
                }}
              />
              <div className="location-overlay">
                <div className="location-pin-container">
                  <div className="location-pin-shadow"></div>
                  <div className="location-pin">
                    <LocationIcon />
                  </div>
                  <div className="location-pin-pulse"></div>
                </div>
              </div>
            </div>
            <div className="location-details">
              <div className="location-header">
                <span className="location-title">{message.message1 || "Shared Location"}</span>
                <span className="location-view-btn">View</span>
              </div>
              <div className="location-info">
                <small className="location-coordinates">
                  {message.latitude.toFixed(6)}, {message.longitude.toFixed(6)}
                </small>
                <div className="location-action">
                  <svg width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1z"/>
                  </svg>
                  <span>Open in Maps</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    
    case 'text':
    default:
      return <div className="message-content">{message.message1}</div>;
  }
};

const Chat = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = async () => {
    try {
      const response = await axios.get(baseurl + 'admin/chats');
      console.log('Fetched chats:', response.data);
      setChats(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching chats:', error);
      setLoading(false);
    }
  };

  const fetchMessages = async (chat) => {
    setLoadingMessages(true);
    try {
      console.log('Fetching messages for users:', chat.user1.id, chat.user2.id);
      const response = await axios.get(baseurl + `admin/messages/${chat.user1.id}/${chat.user2.id}`);
      console.log('Fetched messages:', response.data);
      
      if (response.data && Array.isArray(response.data.messages)) {
        const sortedMessages = [...response.data.messages].sort((a, b) => 
          new Date(a.date) - new Date(b.date)
        );
        setMessages(sortedMessages);
        setSelectedChat({
          ...chat,
          user1: response.data.user1,
          user2: response.data.user2
        });
      } else {
        console.error('Invalid messages response:', response.data);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setMessages([]);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleChatSelect = (chat, e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Selected chat:', chat);
    setSelectedChat(chat);
    fetchMessages(chat);
  };

  const handleUserClick = (user) => {
    if (!user) return;
    
    if (user.role === 'provider') {
      navigate('/admin/provider/detail', { state: { user } });
    } else {
      navigate('/admin/user/detail/' + user.id, { state: { user } });
    }
  };

  if (loading) {
    return <div className="loading">Loading chats...</div>;
  }

  return (
    <div className="chat-container">
      <div className="chat-list">
        <div className="chat-list-header">
          <div className="search-box">
            <SearchIcon />
            <input type="text" placeholder="Search chats..." />
          </div>
        </div>
        <div className="chat-list-content">
          {chats.map((chat) => (
            <div
              key={chat.user1.id + chat.user2.id}
              className={`chat-item ${
                selectedChat &&
                selectedChat.user1.id === chat.user1.id &&
                selectedChat.user2.id === chat.user2.id
                  ? 'selected'
                  : ''
              }`}
              onClick={(e) => handleChatSelect(chat, e)}
            >
              <div className="chat-item-avatar">
                <AvatarCircle 
                  name={chat.user1.fullName} 
                  profileImage={chat.user1.profileImage} 
                  size={48} 
                />
                <div className="online-indicator" />
              </div>
              <div className="chat-item-content">
                <div className="chat-item-header">
                  <span className="chat-item-name">{chat.user1.fullName}</span>
                  <span className="chat-item-time">{formateDate(chat.lastMessageDate)}</span>
                </div>
                <div className="chat-item-message">
                  {chat.lastMessage && chat.lastMessage.message1 ? chat.lastMessage.message1 : 
                   chat.lastMessage && chat.lastMessage.type === 'image' ? 'Sent an image' :
                   chat.lastMessage && chat.lastMessage.type === 'voice' ? 'Sent a voice message' :
                   chat.lastMessage && chat.lastMessage.type === 'location' ? 'Shared a location' :
                   'No messages yet'}
                </div>
              </div>
              {chat.lastMessage && chat.lastMessage.readed === 0 && (
                <div className="unread-indicator">1</div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="chat-messages mt-[100px]">
        {selectedChat ? (
          <>
            <div className="chat-header">
              <div className="chat-header-users">
                <div className="chat-header-user">
                  <div 
                    className="chat-header-avatar"
                    onClick={() => handleUserClick(selectedChat.user1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <AvatarCircle name={selectedChat.user1.fullName} profileImage={selectedChat.user1.profileImage} size={40} />
                    <div className="online-indicator" />
                  </div>
                  <div className="chat-header-info">
                    <h2>{selectedChat.user1.fullName}</h2>
                    <div className="chat-header-details">
                      <span className="phone-number">{selectedChat.user1.phoneNumber || selectedChat.user1.phone}</span>
                      <span className="username">({selectedChat.user1.userName || selectedChat.user1.username})</span>
                      <span className={`user-role ${selectedChat.user1.role === 'user' ? 'user' : 'provider'}`}>
                        {selectedChat.user1.role === 'user' ? 'مستخدم' : 'مزود الخدمة'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="chat-header-separator">
                  <i className="fas fa-exchange-alt"></i>
                </div>

                <div className="chat-header-user">
                  <div 
                    className="chat-header-avatar"
                    onClick={() => handleUserClick(selectedChat.user2)}
                    style={{ cursor: 'pointer' }}
                  >
                    <AvatarCircle name={selectedChat.user2.fullName} profileImage={selectedChat.user2.profileImage} size={40} />
                    <div className="online-indicator" />
                  </div>
                  <div className="chat-header-info">
                    <h2>{selectedChat.user2.fullName}</h2>
                    <div className="chat-header-details">
                      <span className="phone-number">{selectedChat.user2.phoneNumber || selectedChat.user2.phone}</span>
                      <span className="username">({selectedChat.user2.userName || selectedChat.user2.username})</span>
                      <span className={`user-role ${selectedChat.user2.role === 'user' ? 'user' : 'provider'}`}>
                        {selectedChat.user2.role === 'user' ? 'مستخدم' : 'مزود الخدمة'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="messages-container">
              {loadingMessages ? (
                <div className="messages-loading">Loading messages...</div>
              ) : messages.length > 0 ? (
                <>
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`message ${
                        message.senderId === selectedChat.user1.id ? 'received' : 'sent'
                      } ${message.type !== 'text' ? `message-type-${message.type}` : ''}`}
                    >
                      <div 
                        className="message-avatar"
                        onClick={() => handleUserClick(
                          message.senderId === selectedChat.user1.id
                            ? selectedChat.user1
                            : selectedChat.user2
                        )}
                        style={{ cursor: 'pointer' }}
                      >
                        <AvatarCircle 
                          name={
                            message.senderId === selectedChat.user1.id
                              ? selectedChat.user1.fullName
                              : selectedChat.user2.fullName
                          } 
                          profileImage={
                            message.senderId === selectedChat.user1.id
                              ? selectedChat.user1.profileImage
                              : selectedChat.user2.profileImage
                          } 
                          size={32} 
                        />
                      </div>
                      <div className="message-content-wrapper">
                        <div className="message-info">
                          <span className="sender-name">
                            {message.senderId === selectedChat.user1.id
                              ? selectedChat.user1.fullName
                              : selectedChat.user2.fullName}
                          </span>
                          <span className="sender-phone">
                            {message.senderId === selectedChat.user1.id
                              ? selectedChat.user1.phoneNumber || selectedChat.user1.phone
                              : selectedChat.user2.phoneNumber || selectedChat.user2.phone}
                          </span>
                          <span className="sender-username">
                            ({message.senderId === selectedChat.user1.id
                              ? selectedChat.user1.userName || selectedChat.user1.username
                              : selectedChat.user2.userName || selectedChat.user2.username})
                          </span>
                          <span className="message-type-indicator">
                            {message.type === 'image' && <ImageIcon />}
                            {message.type === 'voice' && <VoiceIcon />}
                            {message.type === 'location' && <LocationIcon />}
                          </span>
                        </div>
                        <div className="message-bubble">
                          <MessageContent message={message} />
                        </div>
                        <div className="message-time">{formateDate(message.date)}</div>
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </>
              ) : (
                <div className="no-messages">No messages yet</div>
              )}
            </div>
          </>
        ) : (
          <div className="no-chat-selected">
            <div className="no-chat-icon">
              <MessageIcon />
            </div>
            <span>Select a chat to start messaging</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
