import React, { useState } from 'react';
import './BulkMessage.css';

const BulkMessage = () => {
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [status, setStatus] = useState('');
  const [sentMessages, setSentMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatPhoneNumber = (number) => {
    const cleaned = number.replace(/\D/g, '');
    const withoutLeadingZero = cleaned.replace(/^0+/, '');
    return withoutLeadingZero.startsWith('966') 
      ? withoutLeadingZero 
      : `966${withoutLeadingZero}`;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setStatus('Image size should be less than 5MB');
        return;
      }
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImage(null);
    setImagePreview('');
  };

  const uploadImage = async (file) => {
    try {
      // First, we need to upload the image to Meta's servers
      const formData = new FormData();
      formData.append('file', file);
      formData.append('messaging_product', 'whatsapp');
      formData.append('type', file.type.split('/')[0]); // 'image', 'video', etc.

      const uploadResponse = await fetch('https://graph.facebook.com/v17.0/YOUR_PHONE_NUMBER_ID/media', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_WHATSAPP_TOKEN}`,
        },
        body: formData
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload image');
      }

      const uploadResult = await uploadResponse.json();
      return uploadResult.id; // This is the media ID we'll use to send the message
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const sendWhatsAppMessage = async (phoneNumber, messageText, mediaId = null) => {
    try {
      let messageBody = {
        messaging_product: "whatsapp",
        to: phoneNumber,
        type: mediaId ? "image" : "text",
      };

      if (mediaId) {
        messageBody.image = { id: mediaId };
        if (messageText) {
          messageBody.text = { body: messageText };
        }
      } else {
        messageBody.text = { body: messageText };
      }

      const response = await fetch('https://graph.facebook.com/v17.0/YOUR_PHONE_NUMBER_ID/messages', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_WHATSAPP_TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'Failed to send message');
      }

      return { success: true };
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
      return { success: false, error: error.message };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const numbers = phoneNumbers
      .split('\n')
      .map(num => num.trim())
      .filter(num => num);

    if (numbers.length === 0) {
      setStatus('Please enter at least one phone number');
      setLoading(false);
      return;
    }

    if (!message.trim() && !image) {
      setStatus('Please enter a message or select an image');
      setLoading(false);
      return;
    }

    let mediaId = null;
    if (image) {
      try {
        mediaId = await uploadImage(image);
      } catch (error) {
        setStatus('Failed to upload image: ' + error.message);
        setLoading(false);
        return;
      }
    }

    const results = await Promise.all(
      numbers.map(async (number) => {
        const formattedNumber = formatPhoneNumber(number);
        const result = await sendWhatsAppMessage(formattedNumber, message.trim(), mediaId);
        
        return {
          number: number,
          message: message.trim(),
          hasImage: !!image,
          imageUrl: imagePreview,
          status: result.success ? 'sent' : 'failed',
          error: result.error,
          timestamp: new Date().toLocaleTimeString()
        };
      })
    );

    setSentMessages([...results, ...sentMessages]);

    const successCount = results.filter(r => r.status === 'sent').length;
    const failureCount = results.filter(r => r.status === 'failed').length;

    setStatus(`Messages sent: ${successCount} successful, ${failureCount} failed`);
    
    setPhoneNumbers('');
    setMessage('');
    setImage(null);
    setImagePreview('');
    setLoading(false);
  };

  const handleResend = async (number, messageText, originalImage) => {
    setLoading(true);
    let mediaId = null;
    
    if (originalImage) {
      try {
        // Re-upload the image for resending
        const response = await fetch(originalImage);
        const blob = await response.blob();
        const file = new File([blob], 'resend-image.jpg', { type: 'image/jpeg' });
        mediaId = await uploadImage(file);
      } catch (error) {
        console.error('Error re-uploading image:', error);
      }
    }

    const formattedNumber = formatPhoneNumber(number);
    const result = await sendWhatsAppMessage(formattedNumber, messageText, mediaId);
    
    setSentMessages(prev => prev.map(msg => 
      msg.number === number ? {
        ...msg,
        status: result.success ? 'sent' : 'failed',
        error: result.error,
        timestamp: new Date().toLocaleTimeString()
      } : msg
    ));
    
    setLoading(false);
  };

  return (
    <div className="bulk-message-container">
      <div className="message-section">
        <h2>Send WhatsApp Messages</h2>
        
        <form onSubmit={handleSubmit} className="message-form">
          <div className="form-group">
            <label htmlFor="phoneNumbers">Phone Numbers (one per line):</label>
            <textarea
              id="phoneNumbers"
              value={phoneNumbers}
              onChange={(e) => setPhoneNumbers(e.target.value)}
              placeholder="Enter phone numbers here&#10;Example:&#10;0501234567&#10;966501234567"
              rows="6"
              required
              disabled={loading}
            />
            <small className="help-text">
              Enter numbers with or without country code (966 will be added automatically if missing)
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="message">Message (optional if image is selected):</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter your message here"
              rows="4"
              disabled={loading}
            />
          </div>

          <div className="form-group">
            <label htmlFor="image">Image (optional):</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              disabled={loading}
              className="file-input"
            />
            {imagePreview && (
              <div className="image-preview">
                <img src={imagePreview} alt="Preview" />
                <button 
                  type="button" 
                  className="remove-image" 
                  onClick={removeImage}
                  disabled={loading}
                >
                  Remove Image
                </button>
              </div>
            )}
          </div>

          <button type="submit" className="send-button" disabled={loading}>
            {loading ? 'Sending...' : 'Send WhatsApp Messages'}
          </button>

          {status && (
            <div className={`status-message ${status.includes('failed') ? 'error' : 'success'}`}>
              {status}
            </div>
          )}
        </form>
      </div>

      <div className="history-section">
        <h3>Message History</h3>
        <div className="message-list">
          {sentMessages.map((msg, index) => (
            <div key={index} className="message-item">
              <div className="message-header">
                <span className="phone-number">{msg.number}</span>
                <span className="timestamp">{msg.timestamp}</span>
              </div>
              {msg.hasImage && (
                <div className="message-image">
                  <img src={msg.imageUrl} alt="Sent" />
                </div>
              )}
              {msg.message && (
                <div className="message-content">{msg.message}</div>
              )}
              <div className={`message-status ${msg.status}`}>
                {msg.status}
                {msg.error && <span className="error-details">: {msg.error}</span>}
              </div>
              <button 
                className="resend-button"
                onClick={() => handleResend(msg.number, msg.message, msg.imageUrl)}
                disabled={loading}
              >
                {loading ? 'Resending...' : 'Resend'}
              </button>
            </div>
          ))}
          {sentMessages.length === 0 && (
            <div className="no-messages">No messages sent yet</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkMessage;
