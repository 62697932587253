import React, { useState, useEffect, useRef } from 'react';
import './Notifications.css';
import { baseurl } from '../constants';

const NOTIFICATION_TYPES = {
    USERS: 'users',
    TOPIC: 'topic'
};

const TOPICS = [
    { id: 'news_updates', name: 'تحديثات الأخبار' },
    { id: 'promotions', name: 'العروض الترويجية' },
    { id: 'system_updates', name: 'تحديثات النظام' }
];

export default function Notifications() {
    const [notificationType, setNotificationType] = useState(NOTIFICATION_TYPES.USERS);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ text: '', type: '' });
    const dropdownRef = useRef(null);
    const messageTimeoutRef = useRef(null);

    useEffect(() => {
        if (notificationType === NOTIFICATION_TYPES.USERS) {
            fetchUsers();
        }
        
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            if (messageTimeoutRef.current) {
                clearTimeout(messageTimeoutRef.current);
            }
        };
    }, [notificationType]);

    const showMessage = (text, type = 'error') => {
        setMessage({ text, type });
        if (messageTimeoutRef.current) {
            clearTimeout(messageTimeoutRef.current);
        }
        messageTimeoutRef.current = setTimeout(() => {
            setMessage({ text: '', type: '' });
        }, 3000);
    };

    const fetchUsers = async () => {
            const response = await fetch(baseurl + 'api/user/all-users', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) throw new Error('Failed to fetch users');
            const data = await response.json();
            setUsers(data);
    };

    const handleSendNotification = async () => {
        if (!title.trim() || !body.trim()) {
            showMessage('يرجى ملء جميع الحقول المطلوبة');
            return;
        }

        setLoading(true);
        try {
            let endpoint = '';
            const formData = new FormData();
            formData.append('title', title.trim());
            formData.append('body', body.trim());

            if (notificationType === NOTIFICATION_TYPES.USERS) {
                if (selectedUsers.length === 0) {
                    showMessage('يرجى اختيار مستخدم واحد على الأقل');
                    setLoading(false);
                    return;
                }
                endpoint = 'api/notifications/send-to-users';
                formData.append('userIds', selectedUsers.join(','));
            } else {
                if (!selectedTopic) {
                    showMessage('يرجى اختيار موضوع');
                    setLoading(false);
                    return;
                }
                endpoint = 'api/notifications/send-to-topic';
                formData.append('topic', selectedTopic);
            }

            // Log FormData contents
            console.log('FormData contents:');
            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            const response = await fetch(baseurl + endpoint, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            
            if (data.success) {
                showMessage('تم إرسال الإشعار بنجاح', 'success');
                // Reset form
                setTitle('');
                setBody('');
                setSelectedUsers([]);
                setSelectedTopic('');
            } else {
                throw new Error(data.message || 'فشل في إرسال الإشعار');
            }
        } catch (error) {
            console.error('Error sending notification:', error);
            showMessage(error.message || 'حدث خطأ أثناء إرسال الإشعار');
        } finally {
            setLoading(false);
        }
    };

    const filteredUsers = users.filter(user => 
        user.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.phone?.includes(searchTerm) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.city?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleUserChange = (userId) => {
        setSelectedUsers(prev => {
            if (prev.includes(userId)) {
                return prev.filter(id => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    const getSelectedUsersText = () => {
        if (selectedUsers.length === 0) return 'اختر المستخدمين';
        return `تم اختيار ${selectedUsers.length} مستخدم`;
    };

    const handleSelectAll = () => {
        setSelectedUsers(users.map(user => user.id));
        setIsOpen(false);
    };

    const handleSelectAllProviders = () => {
        const providerIds = users
            .filter(user => user.role === 'provider')
            .map(user => user.id);
        setSelectedUsers(providerIds);
        setIsOpen(false);
    };

    const handleSelectAllCustomers = () => {
        const customerIds = users
            .filter(user => user.role === 'user')
            .map(user => user.id);
        setSelectedUsers(customerIds);
        setIsOpen(false);
    };

    return (
        <div className="notifications-container" dir="rtl">
            <div className="notifications-card">
                <h2 className="page-title">إرسال إشعارات</h2>

                {message.text && (
                    <div className={`message ${message.type}`}>
                        {message.text}
                    </div>
                )}

                <div className="form-group">
                    <label>نوع الإشعار:</label>
                    <div className="notification-type-selector">
                        <button
                            className={`type-button ${notificationType === NOTIFICATION_TYPES.USERS ? 'active' : ''}`}
                            onClick={() => setNotificationType(NOTIFICATION_TYPES.USERS)}
                        >
                            إرسال لمستخدمين محددين
                        </button>
                        <button
                            className={`type-button ${notificationType === NOTIFICATION_TYPES.TOPIC ? 'active' : ''}`}
                            onClick={() => setNotificationType(NOTIFICATION_TYPES.TOPIC)}
                        >
                            إرسال لموضوع
                        </button>
                    </div>
                </div>

                {notificationType === NOTIFICATION_TYPES.USERS ? (
                    <div className="form-group" ref={dropdownRef}>
                        <label>اختر المستخدمين:</label>
                        <div className="dropdown-container">
                            <div className="quick-select-buttons">
                                <button 
                                    className="quick-select-btn" 
                                    onClick={handleSelectAll}
                                    type="button"
                                >
                                    تحديد الكل
                                </button>
                                <button 
                                    className="quick-select-btn" 
                                    onClick={handleSelectAllProviders}
                                    type="button"
                                >
                                    تحديد كل مزودي الخدمة
                                </button>
                                <button 
                                    className="quick-select-btn" 
                                    onClick={handleSelectAllCustomers}
                                    type="button"
                                >
                                    تحديد كل المستخدمين
                                </button>
                            </div>
                            <div 
                                className="dropdown-header"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <span>{getSelectedUsersText()}</span>
                                <i className={`fas fa-chevron-down ${isOpen ? 'open' : ''}`}></i>
                            </div>
                            {isOpen && (
                                <div className="dropdown-content">
                                    <div className="dropdown-search">
                                        <input
                                            type="text"
                                            placeholder="ابحث عن مستخدم..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="search-input"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </div>
                                    <div className="dropdown-list">
                                        {filteredUsers.map((user) => (
                                            <div
                                                key={user.id}
                                                className={`dropdown-item ${selectedUsers.includes(user.id) ? 'selected' : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleUserChange(user.id);
                                                }}
                                            >
                                                <div className="user-info">
                                                    <div className="user-primary">
                                                        <div className="user-name">{user.fullName}</div>
                                                        {user.status === 'active' && (
                                                            <span className="user-status active">نشط</span>
                                                        )}
                                                    </div>
                                                    <div className="user-details">
                                                        <span className="user-phone">{user.phone}</span>
                                                        {user.city && (
                                                            <span className="user-location">
                                                                <i className="fas fa-map-marker-alt"></i> {user.city}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedUsers.includes(user.id)}
                                                        onChange={() => {}}
                                                        onClick={(e) => e.stopPropagation()}
                                                        className="user-checkbox"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {filteredUsers.length === 0 && (
                                            <div className="no-results">
                                                لا يوجد مستخدمين مطابقين للبحث
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="form-group">
                        <label>اختر الموضوع:</label>
                        <select
                            value={selectedTopic}
                            onChange={(e) => setSelectedTopic(e.target.value)}
                            className="topic-select"
                        >
                            <option value="">اختر موضوع</option>
                            {TOPICS.map(topic => (
                                <option key={topic.id} value={topic.id}>
                                    {topic.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <div className="form-group">
                    <label>عنوان الإشعار:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-input"
                        placeholder="أدخل عنوان الإشعار"
                    />
                </div>

                <div className="form-group">
                    <label>نص الإشعار:</label>
                    <textarea
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        className="form-textarea"
                        rows="4"
                        placeholder="أدخل نص الإشعار"
                    />
                </div>

                <button
                    className="send-button"
                    onClick={handleSendNotification}
                    disabled={loading || !title || !body || (notificationType === NOTIFICATION_TYPES.USERS && selectedUsers.length === 0) || (notificationType === NOTIFICATION_TYPES.TOPIC && !selectedTopic)}
                >
                    {loading ? 'جاري الإرسال...' : 'إرسال الإشعار'}
                </button>
            </div>
        </div>
    );
}