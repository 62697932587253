import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format, differenceInHours } from 'date-fns';
import { ar } from 'date-fns/locale';
import { baseurl } from '../../constants';
import './BookingDetail.css';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';

const StatusBadge = ({ status, isPaid }) => {
  const getStatusInfo = () => {
    if (status === 0) {
      return { 
        text: 'معلق', 
        color: 'yellow'  // orange equivalent in Tailwind
      };
    } else if (status === 1) {
      return { 
        text: isPaid ? 'مؤكد' : 'في انتظار الدفع',
        color: isPaid ? 'green' : 'gray'
      };
    } else if (status === 2) {
      return { 
        text: 'ملغي',
        color: 'red'
      };
    } else {
      return { 
        text: 'غير معروف',
        color: 'gray'
      };
    }
  };

  const { text, color } = getStatusInfo();
  
  const colorClasses = {
    yellow: 'bg-yellow-100 text-yellow-800',
    green: 'bg-green-100 text-green-800',
    red: 'bg-red-100 text-red-800',
    gray: 'bg-gray-100 text-gray-800'
  };

  return (
    <span className={`px-4 py-2 rounded-full text-sm font-medium ${colorClasses[color]} flex items-center`}>
      <span className={`w-2 h-2 rounded-full bg-${color}-500 mr-2`}></span>
      {text}
    </span>
  );
};

const InfoCard = ({ title, children, className = "" }) => (
  <div className={`bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-300 ${className}`}>
    <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
      <span className="w-1 h-6 bg-green-500 rounded-full mr-3"></span>
      {title}
    </h3>
    {children}
  </div>
);

const DetailRow = ({ label, value, highlight = false }) => (
  <div className={`flex justify-between py-3 border-b border-gray-100 last:border-0 ${highlight ? 'bg-green-50 px-3 rounded-lg' : ''}`}>
    <span className="text-gray-600">{label}</span>
    <span className={`font-medium ${highlight ? 'text-green-700' : 'text-gray-800'}`}>{value}</span>
  </div>
);

const BookingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('bookingId', id);

        const response = await axios.post(
          `${baseurl}booking/details`,
          formData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        setData(response.data);
      } catch (err) {
        setError(err.response?.data?.message || 'حدث خطأ أثناء جلب بيانات الحجز');
        console.error('Error fetching booking details:', err);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBookingDetails();
    }
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-green-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-red-600 text-xl mb-4">{error}</div>
        <button
          onClick={() => navigate('/bookings')}
          className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
        >
          العودة للحجوزات
        </button>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd MMMM yyyy - hh:mm a', { locale: ar });
  };

  const calculateHours = (startDate, endDate) => {
    return differenceInHours(new Date(endDate), new Date(startDate));
  };

  const formatNumber = (number) => {
    return Number(number).toFixed(2);
  };

  const { booking, user, provider, service } = data;

  const exportToPDF = () => {
    // Create a temporary div for PDF content
    const element = document.createElement('div');
    element.style.direction = 'rtl';
    element.style.fontFamily = 'Cairo, Arial, sans-serif';
    element.style.padding = '20px';
    element.style.backgroundColor = '#f8f9fa';
    
    // Add app logo and header section
    const header = document.createElement('div');
    header.style.display = 'flex';
    header.style.justifyContent = 'space-between';
    header.style.alignItems = 'center';
    header.style.marginBottom = '30px';
    header.style.padding = '15px';
    header.style.backgroundColor = '#ffffff';
    header.style.borderRadius = '8px';
    header.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
    
    // Add logo placeholder
    const logoDiv = document.createElement('div');
    logoDiv.style.fontWeight = 'bold';
    logoDiv.style.fontSize = '24px';
    logoDiv.style.color = '#4472C4';
    logoDiv.textContent = 'اسم التطبيق';
    header.appendChild(logoDiv);
    
    // Add title
    const titleDiv = document.createElement('div');
    titleDiv.style.textAlign = 'center';
    
    const title = document.createElement('h1');
    title.style.color = '#4472C4';
    title.style.margin = '0';
    title.style.fontSize = '24px';
    title.textContent = `تفاصيل الحجز`;
    titleDiv.appendChild(title);
    
    const subTitle = document.createElement('p');
    subTitle.style.margin = '5px 0 0 0';
    subTitle.style.color = '#666';
    subTitle.style.fontSize = '14px';
    subTitle.textContent = `رقم الحجز: ${booking.id}`;
    titleDiv.appendChild(subTitle);
    
    header.appendChild(titleDiv);
    
    // Add status badge
    const statusDiv = document.createElement('div');
    const statusBadge = document.createElement('span');
    statusBadge.style.padding = '8px 16px';
    statusBadge.style.borderRadius = '50px';
    statusBadge.style.fontWeight = 'bold';
    statusBadge.style.fontSize = '14px';
    
    if (booking.isPaid) {
        statusBadge.style.backgroundColor = '#d4edda';
        statusBadge.style.color = '#155724';
        statusBadge.textContent = 'مدفوع';
    } else {
        statusBadge.style.backgroundColor = '#fff3cd';
        statusBadge.style.color = '#856404';
        statusBadge.textContent = 'غير مدفوع';
    }
    
    statusDiv.appendChild(statusBadge);
    header.appendChild(statusDiv);
    
    element.appendChild(header);
    
    // Helper function to create a section
    const createSection = (title, content, icon = null) => {
        const section = document.createElement('div');
        section.style.marginBottom = '25px';
        section.style.backgroundColor = '#ffffff';
        section.style.borderRadius = '8px';
        section.style.padding = '15px';
        section.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
        
        const sectionHeader = document.createElement('div');
        sectionHeader.style.borderBottom = '1px solid #eee';
        sectionHeader.style.paddingBottom = '10px';
        sectionHeader.style.marginBottom = '15px';
        sectionHeader.style.display = 'flex';
        sectionHeader.style.alignItems = 'center';
        
        if (icon) {
            const iconSpan = document.createElement('span');
            iconSpan.style.marginLeft = '10px';
            iconSpan.style.color = '#4472C4';
            iconSpan.innerHTML = icon;
            sectionHeader.appendChild(iconSpan);
        }
        
        const sectionTitle = document.createElement('h2');
        sectionTitle.style.color = '#4472C4';
        sectionTitle.style.margin = '0';
        sectionTitle.style.fontSize = '18px';
        sectionTitle.textContent = title;
        sectionHeader.appendChild(sectionTitle);
        
        section.appendChild(sectionHeader);
        
        const details = document.createElement('div');
        
        // Create a table for the data
        const table = document.createElement('table');
        table.style.width = '100%';
        table.style.borderCollapse = 'collapse';
        
        content.forEach(([label, value], index) => {
            const row = document.createElement('tr');
            
            // Alternate row background color
            if (index % 2 === 1) {
                row.style.backgroundColor = '#f8f9fa';
            }
            
            const labelCell = document.createElement('td');
            labelCell.style.padding = '8px 4px';
            labelCell.style.fontWeight = 'bold';
            labelCell.style.width = '40%';
            labelCell.style.fontSize = '14px';
            labelCell.textContent = label;
            
            const valueCell = document.createElement('td');
            valueCell.style.padding = '8px 4px';
            valueCell.style.fontSize = '14px';
            valueCell.textContent = value;
            
            row.appendChild(labelCell);
            row.appendChild(valueCell);
            table.appendChild(row);
        });
        
        details.appendChild(table);
        section.appendChild(details);
        return section;
    };
    
    // Financial summary section
    const financialSummary = document.createElement('div');
    financialSummary.style.display = 'flex';
    financialSummary.style.justifyContent = 'space-between';
    financialSummary.style.marginBottom = '25px';
    financialSummary.style.gap = '15px';
    
    const createFinancialCard = (title, value, color) => {
        const card = document.createElement('div');
        card.style.flex = '1';
        card.style.backgroundColor = '#ffffff';
        card.style.borderRadius = '8px';
        card.style.padding = '15px';
        card.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
        card.style.textAlign = 'center';
        card.style.borderTop = `4px solid ${color}`;
        
        const cardTitle = document.createElement('div');
        cardTitle.style.fontSize = '14px';
        cardTitle.style.color = '#666';
        cardTitle.style.marginBottom = '10px';
        cardTitle.textContent = title;
        
        const cardValue = document.createElement('div');
        cardValue.style.fontSize = '20px';
        cardValue.style.fontWeight = 'bold';
        cardValue.style.color = color;
        cardValue.textContent = value;
        
        card.appendChild(cardTitle);
        card.appendChild(cardValue);
        
        return card;
    };
    
    financialSummary.appendChild(
        createFinancialCard('إجمالي المبلغ', `${formatNumber(booking.totalCost)} ريال`, '#4472C4')
    );
    
    financialSummary.appendChild(
        createFinancialCard('عمولة التطبيق', `${formatNumber(data.appCommission)} ريال`, '#5B9BD5')
    );
    
    financialSummary.appendChild(
        createFinancialCard('عمولة مزود الخدمة', `${formatNumber(data.providerCommission)} ريال`, '#70AD47')
    );
    
    element.appendChild(financialSummary);
    
    // Add sections with Font Awesome icons (as SVG to ensure they render in PDF)
    const bookingIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/></svg>';
    element.appendChild(
        createSection('معلومات الحجز', [
            ['رقم الحجز', booking.id],
            ['تاريخ الحجز', formatDate(booking.createdAt)],
            ['عدد الأشخاص', booking.sets],
            ['التكلفة الإجمالية', `${formatNumber(booking.totalCost)} ريال`],
            ['تاريخ البدء', formatDate(booking.startDate)],
            ['تاريخ الانتهاء', formatDate(booking.endDate)],
            ['مدة الحجز', `${calculateHours(booking.startDate, booking.endDate)} ساعة`],
            ['حالة الدفع', booking.isPaid ? 'مدفوع' : 'غير مدفوع']
        ], bookingIcon)
    );
    
    const serviceIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg>';
    element.appendChild(
        createSection('معلومات الخدمة', [
            ['اسم الخدمة', service.name],
            ['السعر الأساسي', `${formatNumber(service.cost)} ريال`],
            ['المدينة', service.city],
            ['العنوان', service.address],
            ['الوصف', service.desc || '-']
        ], serviceIcon)
    );
    
    const userIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/></svg>';
    element.appendChild(
        createSection('معلومات العميل', [
            ['الاسم', user.fullName],
            ['رقم الجوال', user.userName],
            ['البريد الإلكتروني', user.email || '-'],
            ['العنوان', user.descAddress || '-']
        ], userIcon)
    );
    
    const providerIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/><path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/><path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/></svg>';
    element.appendChild(
        createSection('معلومات مزود الخدمة', [
            ['الاسم', provider.fullName],
            ['التقييم', `${provider.rate} نجوم`],
            ['رقم الجوال', provider.userName],
            ['البريد الإلكتروني', provider.email || '-'],
            ['العنوان', provider.descAddress || '-'],
            ['البنك', provider.bank || '-'],
            ['رقم الآيبان', provider.ibanNumber || '-']
        ], providerIcon)
    );
    
    // Add footer
    const footer = document.createElement('div');
    footer.style.marginTop = '30px';
    footer.style.textAlign = 'center';
    footer.style.padding = '15px';
    footer.style.backgroundColor = '#ffffff';
    footer.style.borderRadius = '8px';
    footer.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
    
    const footerText = document.createElement('p');
    footerText.style.margin = '0';
    footerText.style.fontSize = '12px';
    footerText.style.color = '#666';
    footerText.textContent = `تم إنشاء هذا التقرير في ${format(new Date(), 'dd/MM/yyyy HH:mm', { locale: ar })}`;
    footer.appendChild(footerText);
    
    element.appendChild(footer);
    
    // Add custom font for better Arabic support
    const style = document.createElement('style');
    style.textContent = `
        @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap');
        
        * {
            font-family: 'Cairo', Arial, sans-serif;
        }
    `;
    document.head.appendChild(style);
    
    // Convert to PDF with better settings for Arabic
    const opt = {
        margin: [15, 15, 15, 15],
        filename: `تفاصيل_الحجز_${booking.id}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
            scale: 2,
            useCORS: true,
            logging: false,
            letterRendering: true
        },
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
            compress: true,
            putOnlyUsedFonts: true,
            floatPrecision: 16,
            text: { autoSize: true } // Ensure text is rendered properly
        }
    };
    
    // First ensure the Cairo font is loaded, then generate PDF
    document.fonts.ready.then(() => {
        html2pdf().from(element).set(opt).save();
    });
};
  const exportToExcel = () => {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    // Define headers with translations
    const headers = [
        'رقم الحجز',
        'تاريخ الإنشاء',
        'عدد الأشخاص',
        'التكلفة الإجمالية',
        'تاريخ البدء',
        'تاريخ الانتهاء',
        'مدة الحجز',
        'حالة الدفع',
        'اسم العميل',
        'رقم جوال العميل',
        'بريد العميل الإلكتروني',
        'عنوان العميل',
        'اسم الخدمة',
        'السعر الأساسي',
        'المدينة',
        'العنوان',
        'وصف الخدمة',
        'مزود الخدمة',
        'تقييم مزود الخدمة',
        'رقم جوال مزود الخدمة',
        'بريد مزود الخدمة الإلكتروني',
        'عنوان مزود الخدمة',
        'البنك',
        'رقم الآيبان',
        'عمولة التطبيق',
        'عمولة مزود الخدمة'
    ];

    // Define values
    const values = [
        booking.id,
        formatDate(booking.createdAt),
        booking.sets,
        formatNumber(booking.totalCost),
        formatDate(booking.startDate),
        formatDate(booking.endDate),
        `${calculateHours(booking.startDate, booking.endDate)} ساعة`,
        booking.isPaid ? 'مدفوع' : 'غير مدفوع',
        user.fullName,
        user.userName,
        user.email || '-',
        user.descAddress || '-',
        service.name,
        formatNumber(service.cost),
        service.city,
        service.address,
        service.desc,
        provider.fullName,
        provider.rate,
        provider.userName,
        provider.email || '-',
        provider.descAddress || '-',
        provider.bank,
        provider.ibanNumber,
        formatNumber(data.appCommission),
        formatNumber(data.providerCommission)
    ];

    // Add headers row
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

    // Add values row
    XLSX.utils.sheet_add_aoa(ws, [values], { origin: "A2" });

    // Set column widths
    const colWidths = headers.map(header => ({ wch: Math.max(header.length * 1.5, 15) }));
    ws['!cols'] = colWidths;

    // Style the headers (blue background, white text, right alignment)
    for (let i = 0; i < headers.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: i });
        if (!ws[cellRef]) ws[cellRef] = {};
        
        // Ensure the cell has a style object
        if (!ws[cellRef].s) ws[cellRef].s = {};

        // Add header styles - bold white text, blue background
        ws[cellRef].s = {
            fill: { fgColor: { rgb: "4472C4" } }, // Blue background
            font: { bold: true, color: { rgb: "FFFFFF" } }, // Bold white text
            alignment: { horizontal: "center", vertical: "center", wrapText: true }, // Center alignment
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } }
            }
        };
        
        // Set right alignment for data cells
        const dataCell = XLSX.utils.encode_cell({ r: 1, c: i });
        if (!ws[dataCell]) ws[dataCell] = {};
        if (!ws[dataCell].s) ws[dataCell].s = {};

        ws[dataCell].s = {
            alignment: { horizontal: "right", vertical: "center", wrapText: true }, // Right alignment
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } }
            }
        };
    }

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'تفاصيل الحجز');

    // Create a second sheet with financial summary
    const summaryWs = XLSX.utils.aoa_to_sheet([
        ['ملخص المبالغ المالية'],
        ['البيان', 'القيمة'],
        ['إجمالي المبلغ', `${formatNumber(booking.totalCost)} ريال`],
        ['عمولة التطبيق', `${formatNumber(data.appCommission)} ريال`],
        ['عمولة مزود الخدمة', `${formatNumber(data.providerCommission)} ريال`]
    ]);

    // Style the summary sheet
    // Set merge for title
    if (!summaryWs['!merges']) summaryWs['!merges'] = [];
    summaryWs['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } });

    // Style cells
    const summaryStyles = {
        A1: { font: { bold: true, sz: 14, color: { rgb: "FFFFFF" } }, alignment: { horizontal: "center" }, fill: { fgColor: { rgb: "4472C4" } } },
        A2: { font: { bold: true, color: { rgb: "FFFFFF" } }, fill: { fgColor: { rgb: "4472C4" } }, alignment: { horizontal: "right" } },
        B2: { font: { bold: true, color: { rgb: "FFFFFF" } }, fill: { fgColor: { rgb: "4472C4" } }, alignment: { horizontal: "right" } },
        A3: { alignment: { horizontal: "right" } },
        B3: { font: { bold: true }, alignment: { horizontal: "right" } },
        A4: { alignment: { horizontal: "right" } },
        B4: { font: { bold: true }, alignment: { horizontal: "right" } },
        A5: { alignment: { horizontal: "right" } },
        B5: { font: { bold: true }, alignment: { horizontal: "right" } }
    };

    Object.keys(summaryStyles).forEach(cellRef => {
        if (!summaryWs[cellRef]) summaryWs[cellRef] = {};
        if (!summaryWs[cellRef].s) summaryWs[cellRef].s = {};
        summaryWs[cellRef].s = {
            ...summaryStyles[cellRef],
            alignment: { ...summaryStyles[cellRef].alignment, vertical: "center" },
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } }
            }
        };
    });

    // Set column widths for summary
    summaryWs['!cols'] = [{ wch: 20 }, { wch: 20 }];

    // Add summary worksheet to workbook
    XLSX.utils.book_append_sheet(wb, summaryWs, 'ملخص مالي');

    // Export file
    XLSX.writeFile(wb, `تفاصيل_الحجز_${booking.id}.xlsx`);
};

return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Back Button and Export Buttons */}
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate('/bookings')}
            className="flex items-center text-gray-600 hover:text-gray-800 transition-colors"
          >
            <i className="fas fa-arrow-right ml-2"></i>
            <span>العودة للحجوزات</span>
          </button>

          <div className="flex gap-4">
            <button
              onClick={exportToExcel}
              className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
            >
              <i className="fas fa-file-excel ml-2"></i>
              تصدير Excel
            </button>
            <button
              onClick={exportToPDF}
              className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
            >
              <i className="fas fa-file-pdf ml-2"></i>
              تصدير PDF
            </button>
          </div>
        </div>

        {/* Header Card */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="mb-4 md:mb-0">
              <div className="flex items-center">
                <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                <h1 className="text-2xl font-bold text-gray-800">تفاصيل الحجز #{booking.id}</h1>
              </div>
              <p className="text-gray-500 mt-2 flex items-center">
                <i className="far fa-clock mr-2"></i>
                {formatDate(booking.createdAt)}
              </p>
            </div>
            <div className="flex flex-col items-end">
            <span className={`px-3 py-1 rounded-full text-sm ${booking.isPaid ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                    {booking.isPaid ? 'مدفوع' : 'غير مدفوع'}
                  </span>              <span className="mt-2 text-sm text-gray-500">رقم الحجز: {booking.id}</span>
            </div>
          </div>
        </div>

        {/* Financial Summary */}
        <InfoCard title="ملخص المبالغ المالية" className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gradient-to-r from-green-50 to-green-100 p-6 rounded-xl">
              <div className="flex items-center justify-between mb-2">
                <p className="text-green-600 font-medium">إجمالي المبلغ</p>
                <i className="fas fa-money-bill-wave text-green-500"></i>
              </div>
              <p className="text-3xl font-bold text-green-700">{formatNumber(booking.totalCost)} ريال</p>
            </div>
            <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-xl">
              <div className="flex items-center justify-between mb-2">
                <p className="text-blue-600 font-medium">عمولة التطبيق</p>
                <i className="fas fa-percentage text-blue-500"></i>
              </div>
              <p className="text-3xl font-bold text-blue-700">{formatNumber(data.appCommission)} ريال</p>
            </div>
            <div className="bg-gradient-to-r from-purple-50 to-purple-100 p-6 rounded-xl">
              <div className="flex items-center justify-between mb-2">
                <p className="text-purple-600 font-medium">عمولة مزود الخدمة</p>
                <i className="fas fa-hand-holding-usd text-purple-500"></i>
              </div>
              <p className="text-3xl font-bold text-purple-700">{formatNumber(data.providerCommission)} ريال</p>
            </div>
          </div>
        </InfoCard>

        <div className="columns-1 md:columns-2 gap-6 space-y-6 [&>*]:break-inside-avoid-column">
          {/* Booking Details */}
          <InfoCard title="معلومات الحجز">
            <div className="space-y-4">
              <DetailRow label="عدد الأشخاص" value={booking.sets} />
              {/* <DetailRow label="سعر الساعة" value={`${formatNumber(service.price)} ريال`} /> */}
              <DetailRow label="التكلفة الإجمالية" value={`${formatNumber(booking.totalCost)} ريال`} highlight={true} />
              <DetailRow label="تاريخ البدء" value={formatDate(booking.startDate)} />
              <DetailRow label="تاريخ الانتهاء" value={formatDate(booking.endDate)} />
              <DetailRow 
                label="مدة الحجز" 
                value={
                  <span className="flex items-center text-blue-700">
                    <i className="far fa-clock ml-2"></i>
                    {calculateHours(booking.startDate, booking.endDate)} ساعة
                  </span>
                } 
                highlight={true}
              />
              <DetailRow 
                label="حالة الدفع" 
                value={
                  <span className={`px-3 py-1 rounded-full text-sm ${booking.isPaid ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                    {booking.isPaid ? 'مدفوع' : 'غير مدفوع'}
                  </span>
                } 
              />
            </div>
          </InfoCard>

          {/* Service Details */}
          <InfoCard title="تفاصيل الخدمة">
            <div className="relative mb-6 rounded-xl overflow-hidden group">
              <img 
                src={`${baseurl}uploads/${service.image}`} 
                alt={service.name} 
                className="w-full h-56 object-cover transform group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
              <div className="absolute bottom-4 left-4 right-4 text-white">
                <h4 className="text-xl font-semibold">{service.name}</h4>
                <p className="text-sm opacity-90">{service.city}</p>
              </div>
            </div>
            <DetailRow label="اسم الخدمة" value={service.name} highlight={true} />
            <DetailRow label="السعر الأساسي" value={`${formatNumber(service.cost)} ريال`} />
            <DetailRow label="المدينة" value={service.city} />
            <DetailRow label="العنوان" value={service.address} />
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <h4 className="font-medium text-gray-700 mb-2">الوصف</h4>
              <p className="text-gray-600 text-sm leading-relaxed">{service.desc}</p>
            </div>
          </InfoCard>

          {/* User Details */}
          <InfoCard title="معلومات العميل">
            <div className="p-4 bg-gray-50 rounded-lg mb-4">
              <div className="flex items-center">
                <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center">
                  <i className="fas fa-user text-green-600 text-xl"></i>
                </div>
                <div className="mr-4">
                  <h4 className="font-semibold text-gray-800">{user.fullName}</h4>
                  <p className="text-sm text-gray-500">{user.email}</p>
                </div>
              </div>
            </div>
            <DetailRow label="رقم الجوال" value={user.userName} />
            <DetailRow label="البريد الإلكتروني" value={user.email} />
            <DetailRow label="العنوان" value={user.descAddress || 'غير محدد'} />
          </InfoCard>

          {/* Provider Details */}
          <InfoCard title="معلومات مزود الخدمة">
            <div className="p-4 bg-gradient-to-r from-green-50 to-green-100 rounded-lg mb-4">
              <div className="flex items-center">
                {provider.profileImage ? (
                  <img 
                    src={`${baseurl}uploads/${provider.profileImage}`} 
                    alt={provider.fullName} 
                    className="w-16 h-16 rounded-full object-cover border-4 border-white shadow-sm"
                  />
                ) : (
                  <div className="w-16 h-16 bg-green-200 rounded-full flex items-center justify-center">
                    <i className="fas fa-user-tie text-green-600 text-2xl"></i>
                  </div>
                )}
                <div className="mr-4">
                  <h4 className="font-semibold text-gray-800 text-lg">{provider.fullName}</h4>
                  <div className="flex items-center mt-1">
                    <i className="fas fa-map-marker-alt text-green-600 text-sm"></i>
                    <span className="text-sm text-gray-600 mr-2">{provider.city}</span>
                  </div>
                </div>
              </div>
            </div>
            <DetailRow label="التقييم" value={
              <div className="flex items-center">
                <span className="text-yellow-500 ml-1">{provider.rate}</span>
                <i className="fas fa-star text-yellow-500"></i>
              </div>
            } />
            <DetailRow label="رقم الجوال" value={provider.userName} />
            <DetailRow label="البريد الإلكتروني" value={provider.email} />
            <DetailRow label="العنوان" value={provider.descAddress} />
            <DetailRow label="البنك" value={provider.bank} highlight={true} />
            <DetailRow label="رقم الآيبان" value={provider.ibanNumber} />
          </InfoCard>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;