import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { baseurl, errorNotify, successNotify } from "../constants";
import { Toaster } from "react-hot-toast";
import useLoader from "../Components/loader/useLoader";
import './Login.css';

export default function Login() {
  const [formData, setFormData] = useState({
    userName: '',
    password: ''
  });
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const navigateTo = useNavigate();
  const [loader, showLoader, hideLoader] = useLoader();

  useEffect(() => {
    // Start animation after component mounts
    setTimeout(() => {
      setAnimationComplete(true);
    }, 1500);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setErrors('');
  };

  const login = async (e) => {
    e.preventDefault();
    if (!formData.userName || !formData.password) {
      setErrors(!formData.userName ? "الرجاء إدخال اسم المستخدم" : "الرجاء إدخال كلمة المرور");
      return;
    }

    setIsLoading(true);
    showLoader();
    const form = new FormData();
    form.append("userName", formData.userName);
    form.append("password", formData.password);

    try {
      const response = await fetch(baseurl + "auth/admin-login", {
        method: "POST",
        referrerPolicy: "unsafe-url",
        body: form
      });
      const json = await response.json();
      hideLoader();
      setIsLoading(false);

      if (json.token) {
        localStorage.setItem("token", json.token);
        localStorage.setItem("id", json.user.id);
        successNotify("تم تسجيل الدخول بنجاح");
        navigateTo("/admin");
      } else {
        setErrors("اسم المستخدم أو كلمة المرور غير صحيحة");
      }
    } catch (error) {
      hideLoader();
      setIsLoading(false);
      errorNotify("حدث خطأ في تسجيل الدخول");
    }
  };

  return (
    <div className="login-container">
      {loader}
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: '#12bf24',
              color: "white",
              padding: '16px',
              borderRadius: '12px',
              fontSize: '14px'
            },
          },
          error: {
            style: {
              background: '#e72e2e',
              color: "white",
              padding: '16px',
              borderRadius: '12px',
              fontSize: '14px'
            },
          },
        }}
      />

      <div className="login-content">
        <div className="login-left-side">
          <div className="login-text-overlay">
            <div className="brand-logo">
              <img 
                src="https://img.icons8.com/fluency/96/00C5E9/mountain.png" 
                alt="طلعات" 
                className="logo-image" 
              />
            </div>
            <div className="brand-name-container">
              <h1 className={`animated-brand-name ${animationComplete ? 'animation-complete' : ''}`}>
                <span className="letter" style={{ animationDelay: '0.1s' }}>ط</span>
                <span className="letter" style={{ animationDelay: '0.2s' }}>ل</span>
                <span className="letter" style={{ animationDelay: '0.3s' }}>ع</span>
                <span className="letter" style={{ animationDelay: '0.4s' }}>ا</span>
                <span className="letter" style={{ animationDelay: '0.5s' }}>ت</span>
              </h1>
            </div>
            <p className="brand-tagline">منصة إدارة الرحلات والمغامرات</p>
            <div className="brand-features">
              <div className="feature-item">
                <div className="feature-icon">
                  <img src="https://img.icons8.com/fluency/48/00C5E9/compass.png" alt="Navigation" />
                </div>
                <span>استكشاف سهل</span>
              </div>
              <div className="feature-item">
                <div className="feature-icon">
                  <img src="https://img.icons8.com/fluency/48/00C5E9/calendar-dashboard.png" alt="Calendar" />
                </div>
                <span>إدارة الحجوزات</span>
              </div>
              <div className="feature-item">
                <div className="feature-icon">
                  <img src="https://img.icons8.com/fluency/48/00C5E9/group.png" alt="Group" />
                </div>
                <span>تنظيم المجموعات</span>
              </div>
            </div>
          </div>
        </div>

        <div className="login-right-side">
          <div className="login-form-wrapper">
            <div className="login-header">
              <div className="welcome-text">
                <h2>مرحباً بك! <span className="wave-emoji">👋</span></h2>
                <p>سجل دخولك للوصول إلى لوحة التحكم</p>
              </div>
            </div>

            {errors && (
              <div className="error-message">
                <img 
                  src="https://img.icons8.com/fluency/48/e72e2e/error-cloud.png" 
                  alt="Error" 
                  className="error-icon" 
                />
                <span>{errors}</span>
              </div>
            )}

            <form onSubmit={login} className="login-form">
              <div className="form-fields">
                <div className="form-group">
                  <label htmlFor="userName">اسم المستخدم</label>
                  <div className="input-group">
                    <input
                      id="userName"
                      name="userName"
                      type="text"
                      placeholder="أدخل اسم المستخدم"
                      value={formData.userName}
                      onChange={handleInputChange}
                      className={errors && !formData.userName ? 'error' : ''}
                    />
                    <img 
                      src="https://img.icons8.com/fluency/48/00C5E9/user-male-circle.png" 
                      alt="User" 
                      className="input-icon" 
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="password">كلمة المرور</label>
                  <div className="input-group">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="أدخل كلمة المرور"
                      value={formData.password}
                      onChange={handleInputChange}
                      className={errors && !formData.password ? 'error' : ''}
                    />
                    <img 
                      src="https://img.icons8.com/fluency/48/00C5E9/password.png" 
                      alt="Password" 
                      className="input-icon" 
                    />
                  </div>
                </div>
              </div>

              <button 
                type="submit" 
                className="submit-button"
                disabled={isLoading}
              >
                <span>{isLoading ? 'جاري تسجيل الدخول...' : 'تسجيل الدخول'}</span>
                {!isLoading && (
                  <img 
                    src="https://img.icons8.com/fluency/48/00C5E9/login-rounded-right.png" 
                    alt="Login" 
                    className="button-icon" 
                  />
                )}
              </button>
            </form>

            <div className="login-footer">
              <p>© {new Date().getFullYear()} طلعات. جميع الحقوق محفوظة</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
